import React from 'react';
import { graphql } from 'react-apollo';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import { errorModal } from 'components/Modal';
import { eventInHome } from 'gql/query';
import { updateEvent } from 'gql/mutation';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/constants';
import { handleError } from 'utils/utils';

const withEventInHome = graphql(eventInHome, {
  props: ({ data }) => {
    return data
      ? {
          eventsInHome:
            data.eventsInHomeAdmin && data.eventsInHomeAdmin.data
              ? data.eventsInHomeAdmin.data
              : [],
          pagination:
            data.eventsInHomeAdmin && data.eventsInHomeAdmin.pagination
              ? data.eventsInHomeAdmin.pagination
              : {},
          loading: data.loading,
          refetchEventInHome: (params) => {
            return data.refetch(params);
          }
        }
      : {};
  },
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        sort: {
          sortKey: 'orderInHome',
          sortType: 'asc'
        }
      }
    };
  }
});

class EventInHomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: DEFAULT_PAGE,
        itemPerPage: DEFAULT_PAGE_SIZE
      }
    };
  }

  renderTableColumn = () => {
    return [
      {
        title: 'Section',
        dataIndex: 'section',
        key: 'section',
        render: (section) => {
          return <div>{`${section.title} ${section.subTitle}`}</div>;
        }
      },
      {
        title: 'Event Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Event Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
      },
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client'
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category'
      },
      {
        title: 'Project Type',
        dataIndex: 'projectType',
        key: 'projectType'
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year'
      },
      {
        title: 'Order',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: ({ id, section }) => (
          <EditDeleteBtn
            link={`/section/${section.id}/event-in-home/${id}`}
            routeState={{ prevPath: '/event-in-home' }}
            notShow
          />
        )
      }
    ];
  };

  handleChangeOrder = (row, order) => {
    this.props.client
      .mutate({
        mutation: updateEvent,
        variables: {
          ...row,
          orderInHome: order
        }
      })
      .then(() => {})
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  callbackChangePage = (pagin) => {
    this.setState({
      pagination: {
        currentPage: pagin.currentPage,
        itemPerPage: pagin.itemPerPage
      }
    });
    this.props.refetchEventInHome(pagin);
  };

  render() {
    const { eventsInHome, loading, pagination } = this.props;

    return (
      <div className="event-in-home-section">
        <TableWithOrder
          columns={this.renderTableColumn()}
          data={eventsInHome}
          loading={loading}
          callbackChangeOrder={this.handleChangeOrder}
          sortingKey="orderInHome"
          pagination={pagination}
          callbackChangePage={this.callbackChangePage}
        />
      </div>
    );
  }
}

export default withEventInHome(EventInHomePage);
