import gql from 'graphql-tag';

export default gql`
  query careers($itemPerPage: Int, $currentPage: Int, $sort: Sort) {
    careers(itemPerPage: $itemPerPage, currentPage: $currentPage, sort: $sort) {
      data {
        id
        title
        orders
      }
      pagination {
        totalCount
        itemPerPage
        currentPage
      }
    }
  }
`;
