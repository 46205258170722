import React from 'react';
import { Button, Form } from 'antd';
import { errorModal, ConfirmModal, successModal } from 'components/Modal';
import { setUserStatus } from 'gql/mutation';
import { handleMutation, handleError } from 'utils/utils';

class BlockUserPage extends React.Component {
  handleBlockUser = () => {
    const {
      location: { search }
    } = this.props;
    const token = search.split('token=')[1];
    ConfirmModal('Do you want to block this user?', () => {
      if (token) {
        try {
          const decodeToken = JSON.parse(window.atob(token.split('.')[1]));
          handleMutation(
            this,
            setUserStatus,
            {
              token,
              id: decodeToken.userId,
              status: 'inactive'
            },
            'setUserStatus',
            () => {
              successModal('Block user successfully!', () => {
                this.props.history.push('/login');
              });
            },
            (err) => {
              errorModal(handleError(err, 'graphQLErrors'));
            }
          );
        } catch (error) {
          errorModal(error);
        }
      } else {
        errorModal('Token is not exist');
      }
    });
  };

  render() {
    return (
      <div className="form-login">
        <Form
          name="normal_login"
          className="login-form"
          style={{ textAlign: 'center' }}
        >
          <Form.Item>
            <Button onClick={this.handleBlockUser} type="danger">
              Block User
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default BlockUserPage;
