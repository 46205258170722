import React from 'react';
import { graphql } from 'react-apollo';
import { Row, Col } from 'antd';
import CustomInput from 'components/CustomInput';
import SaveCancelBtn from 'components/SaveCancelBtn';
import UploadFile from 'components/UploadFile';
import { successModal, errorModal } from 'components/Modal';
import { addFeedback, updateFeedback } from 'gql/mutation';
import { singleFeedback } from 'gql/query';
import { handleMutation, handleError } from 'utils/utils';

const withFeedback = graphql(singleFeedback, {
  props: ({ data, ownProps }) => {
    return ownProps.match.params['id'] && data
      ? {
          feedback: data.singleAdminFeedback ? data.singleAdminFeedback : {},
          loading: data.loading,
          refetchFeedback: () => {
            return data.refetch();
          }
        }
      : {
          refetchFeedback: () => {}
        };
  },
  options: ({ match, location }) => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: match.params['id'] || ''
      },
      onError: (err) => {
        if (!location.pathname.includes('create')) {
          errorModal(handleError(err, 'graphQLErrors'));
        }
      }
    };
  }
});

class AddEditClientFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: '',
      clientPosition: '',
      operator: '',
      description: '',
      urlImg: '',
      loading: props.loading,
      disabled: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { feedback, loading } = nextProps;
    if (prevState.loading && feedback) {
      return {
        loading,
        ...feedback
      };
    }
    return null;
  }

  handleChange = (e, field) => {
    const { value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [field]: value,
        disabled: false
      };
    });
  };

  handleUploadImage = (url) => {
    this.setState({
      urlImg: url,
      disabled: false
    });
  };

  validateForm = () => {
    const {
      clientName,
      clientPosition,
      operator,
      description,
      urlImg
    } = this.state;
    if (clientName && clientPosition && operator && description) {
      return {
        clientName,
        clientPosition,
        operator,
        description,
        urlImg
      };
    }
    return false;
  };

  handleSaveSection = () => {
    const {
      match: { params },
      history
    } = this.props;

    if (this.validateForm()) {
      let mutation = addFeedback;
      let formData = this.validateForm();
      let dataKey = 'addFeedback';

      if (params['id']) {
        mutation = updateFeedback;
        formData.id = params['id'];
        dataKey = 'updateFeedback';
      }

      handleMutation(
        this,
        mutation,
        formData,
        dataKey,
        () => {
          successModal('Save feedback successfully.', () => {
            history.push('/feedback');
          });
        },
        () => {
          errorModal('Fail to save feedback!!!');
        }
      );
    }
  };

  handleCancel = () => {
    this.props.history.push('/feedback');
  };

  render() {
    const {
      clientName,
      clientPosition,
      operator,
      description,
      urlImg,
      loading,
      disabled
    } = this.state;

    return (
      <div className="event-section-detail">
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <CustomInput
              label="Client Name"
              value={clientName}
              onChange={(e) => this.handleChange(e, 'clientName')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Position"
              value={clientPosition}
              onChange={(e) => this.handleChange(e, 'clientPosition')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Operator"
              value={operator}
              onChange={(e) => this.handleChange(e, 'operator')}
            />
          </Col>
          <Col span={24}>
            <CustomInput
              rows={8}
              type="textarea"
              label="Description"
              value={description}
              onChange={(e) => this.handleChange(e, 'description')}
            />
          </Col>
          <Col span={24}>
            <UploadFile
              url={urlImg}
              loading={loading}
              onUploadFile={this.handleUploadImage}
              imgWidth="148px"
              imgHeight="148px"
            />
          </Col>
        </Row>
        <SaveCancelBtn
          saveCallback={this.handleSaveSection}
          cancelCallback={this.handleCancel}
          disabled={!this.validateForm() || disabled}
        />
      </div>
    );
  }
}

export default withFeedback(AddEditClientFeedback);
