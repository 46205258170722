import gql from 'graphql-tag';

export default gql`
  mutation addEvent(
    $eventSectionId: String!
    $title: String!
    $description: String!
    $urlImg: String
    $client: String
    $brand: String
    $category: String
    $projectType: String
    $year: String
    $showInHome: Boolean
  ) {
    addEvent(
      eventSectionId: $eventSectionId
      title: $title
      description: $description
      urlImg: $urlImg
      client: $client
      brand: $brand
      category: $category
      projectType: $projectType
      year: $year
      showInHome: $showInHome
    ) {
      id
    }
  }
`;
