import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import ImageLoader from 'components/ImageLoader';
import { errorModal, successModal } from 'components/Modal';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/constants';
import { handleError } from 'utils/utils';

class ImageLinkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      deleted: false,
      loading: true,
      pagination: {
        currentPage: DEFAULT_PAGE,
        itemPerPage: DEFAULT_PAGE_SIZE
      }
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(__, prevState) {
    const { data } = this.state;
    const { pagination } = prevState;
    if (_.isEmpty(data) && data !== prevState.data) {
      this.setState(
        {
          pagination: {
            ...pagination,
            currentPage:
              pagination.currentPage > 1 ? pagination.currentPage - 1 : 1
          }
        },
        () => {
          this.fetchData(true);
        }
      );
    }
  }

  fetchData = (deleted = false, refetchDataAfterDelete = false) => {
    const { fetchQuery, queryKey } = this.props;
    const { pagination } = this.state;
    this.props.client
      .query({
        query: fetchQuery,
        variables: {
          ...pagination,
          sort: {
            sortKey: 'orders',
            sortType: 'asc'
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        if (data && data[queryKey].data) {
          this.setState({
            data: data[queryKey].data,
            pagination: data[queryKey].pagination,
            loading: false,
            deleted
          });
          if (refetchDataAfterDelete) {
            successModal('Delete item successfully.');
          }
        }
      })
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  handleChangeOrder = (row, order) => {
    const { updateMutation } = this.props;
    this.props.client
      .mutate({
        mutation: updateMutation,
        variables: {
          ...row,
          orders: order
        }
      })
      .then(() => {})
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  callbackChangePage = (pagin) => {
    this.setState(
      {
        pagination: {
          currentPage: pagin.currentPage,
          itemPerPage: pagin.itemPerPage
        },
        loading: true
      },
      () => this.fetchData()
    );
  };

  renderTableColumn = () => {
    const { deleteMutation, imgClass, editLink } = this.props;
    return [
      {
        title: 'Image',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        render: (imgUrl) => {
          return <ImageLoader url={imgUrl} imgClass={imgClass} />;
        }
      },
      {
        title: 'Order',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: ({ id }) => (
          <EditDeleteBtn
            link={`${editLink}/${id}`}
            itemId={id}
            deleteMutation={deleteMutation}
            refresh={() => this.fetchData(true, true)}
          />
        )
      }
    ];
  };

  render() {
    const { history, addBtnText, createLink } = this.props;
    const { data, loading, deleted, pagination } = this.state;

    return (
      <div className="image-section">
        <div className="add-button">
          <Button type="primary" onClick={() => history.push(createLink)}>
            {addBtnText}
          </Button>
        </div>
        <TableWithOrder
          columns={this.renderTableColumn()}
          data={data}
          loading={loading}
          deleted={deleted}
          callbackChangeOrder={this.handleChangeOrder}
          sortingKey="orders"
          pagination={pagination}
          callbackChangePage={this.callbackChangePage}
        />
      </div>
    );
  }
}

ImageLinkList.propTypes = {
  fetchQuery: PropTypes.any.isRequired,
  queryKey: PropTypes.string.isRequired,
  updateMutation: PropTypes.any.isRequired,
  deleteMutation: PropTypes.any.isRequired,
  imgClass: PropTypes.string.isRequired,
  createLink: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  addBtnText: PropTypes.string
};

ImageLinkList.defaultProps = {
  addBtnText: 'Add'
};

export default withApollo(withRouter(ImageLinkList));
