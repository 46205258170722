import gql from 'graphql-tag';

export default gql`
  query getVideo($id: String!) {
    getVideo(id: $id) {
      id
      imgUrl
    }
  }
`;
