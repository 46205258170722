import gql from 'graphql-tag';

export default gql`
  mutation updateEventContent(
    $id: String!
    $type: String
    $urlContent: String
    $orders: Int
  ) {
    updateEventContent(
      id: $id
      type: $type
      urlContent: $urlContent
      orders: $orders
    ) {
      id
    }
  }
`;
