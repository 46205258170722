import gql from 'graphql-tag';

export default gql`
  query singleAdminFeedback($id: String!) {
    singleAdminFeedback(id: $id) {
      clientName
      clientPosition
      operator
      description
      urlImg
    }
  }
`;
