import gql from 'graphql-tag';

export default gql`
  mutation updateCareer(
    $id: String!
    $title: String
    $content: String
    $orders: Int
  ) {
    updateCareer(id: $id, title: $title, content: $content, orders: $orders) {
      id
    }
  }
`;
