import gql from 'graphql-tag';

export default gql`
  query singleAdminCareer($id: String!) {
    singleAdminCareer(id: $id) {
      title
      content
    }
  }
`;
