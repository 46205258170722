import gql from 'graphql-tag';

export default gql`
  query eventSectionsAdmin($itemPerPage: Int, $currentPage: Int, $sort: Sort) {
    eventSectionsAdmin(
      itemPerPage: $itemPerPage
      currentPage: $currentPage
      sort: $sort
    ) {
      data {
        id
        title
        subTitle
        description
        orders
      }
      pagination {
        totalCount
        itemPerPage
        currentPage
      }
    }
  }
`;
