import { errorModal, successModal, loadingModal } from 'components/Modal';

export const uploadFileToServer = (
  file,
  _this,
  successCallback,
  errorCallback,
  isVideo = false
) => {
  const acceptFileTypes = ['jpg', 'jpeg', 'png', 'mp4'];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      let isInValid = false;
      const fileName = file.name;
      for (let i = 0; i < acceptFileTypes.length; i++) {
        const fileType = acceptFileTypes[i];
        if (
          fileName
            .substr(fileName.length - fileType.length, fileType.length)
            .toLowerCase() === fileType.toLowerCase()
        ) {
          isInValid = true;
          break;
        }
      }
      const fileSize = file.size / 1024 / 1024;
      const inValidFileSize = isVideo ? fileSize > 350 : fileSize > 5.8;
      if (inValidFileSize) {
        errorModal(`File size cannot larger than ${isVideo ? '350mb' : '5.8mb'}`);
        errorCallback();
        return;
      }
      if (!isInValid) {
        errorModal('Only accept image type: .png, .jpg and .jpeg');
        errorCallback();
        return;
      }
      _this.setState(
        {
          fileName: file.name
        },
        () => successCallback()
      );
    };
    reader.readAsDataURL(file);
  }
};

export const handleMutation = (
  _this,
  mutation,
  formData,
  dataKey,
  successCallback = () => {},
  failureCallback = () => {}
) => {
  loadingModal();
  _this.props.client
    .mutate({
      mutation,
      variables: formData
    })
    .then(({ data }) => {
      if (data && data[dataKey]) {
        successCallback(data[dataKey]);
      }
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const handleDeleteItem = (_this, promiseRefetch) => {
  loadingModal();
  _this.setState(
    {
      deleted: true
    },
    () => {
      promiseRefetch
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              _this.setState({
                deleted: false
              });
            }, 100);
            successModal('Delete item successfully.');
          }
        })
        .catch((err) => {
          errorModal(err.graphQLErrors[0] ? err.graphQLErrors[0].message : err);
        });
    }
  );
};

export const handleError = (err, key = '') => {
  if (err && err[key] && err[key][0] && err[key][0].message) {
    return err[key][0].message;
  }
  return err;
};
