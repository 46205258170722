import gql from 'graphql-tag';

export default gql`
  mutation addEventContent(
    $eventId: String!
    $type: String!
    $urlContent: String
  ) {
    addEventContent(eventId: $eventId, type: $type, urlContent: $urlContent) {
      id
    }
  }
`;
