import gql from 'graphql-tag';

export default gql`
  query singleAdminAward($id: String!) {
    singleAdminAward(id: $id) {
      imgUrl
      orders
    }
  }
`;
