import gql from 'graphql-tag';

export default gql`
  query eventContentAdmin($id: String!) {
    eventContentAdmin(id: $id) {
      type
      urlContent
      orders
    }
  }
`;
