import React from 'react';
import { Row, Col } from 'antd';
import { graphql } from 'react-apollo';
import UploadFile from 'components/UploadFile';
import getVideo from 'gql/query/getVideo';
import { handleMutation } from 'utils/utils';
import { addVideo, deleteVideo } from 'gql/mutation';
import { successModal } from 'components/Modal';
import { errorModal } from 'components/Modal';

const withVideo = graphql(getVideo, {
  props: ({ data }) => {
    return data
      ? {
          video:
            data.getVideo || {},
          loading: data.loading,
          refetchVideo: (params) => {
            return data.refetch(params);
          }
        }
      : {
          video: {},
          refetchVideo: () => {}
        };
  },
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        id: "introduce-video"
      }
    };
  }
});

class HomeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: '',
      clientPosition: '',
      operator: '',
      description: '',
      urlImg: '',
      loading: props.loading,
      disabled: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { feedback, loading } = nextProps;
    if (prevState.loading && feedback) {
      return {
        loading,
        ...feedback
      };
    }
    return null;
  }

  handleChange = (e, field) => {
    const { value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [field]: value,
        disabled: false
      };
    });
  };

  handleUploadVideo = (url) => {
    handleMutation(this, addVideo, {
      id: 'introduce-video',
      videoLink: url
    },
      'addVideo', 
      () => {
        successModal('Save new Video success!!');
        this.props.refetchVideo();
      },
      () => {
        errorModal("Failed to save new video!!");
        this.props.refetchVideo();
      }
    )
  };

  handleDeleteVideo = () => {
    handleMutation(this, deleteVideo, {
      id: 'introduce-video'
    },
      'deleteVideo', 
      () => {
        successModal('Delete Video success!!');
        this.props.refetchVideo();
      },
      () => {
        errorModal("Failed to delete video!!");
        this.props.refetchVideo();
      }
    )
  }

  render() {
    const {
      loading,
    } = this.state;

    const { video } = this.props;

    return (
      <div className="event-section-detail">
        <Row gutter={[24, 24]}>
        <Col span={24}>
          <UploadFile
            url={video.imgUrl}
            loading={loading}
            onUploadFile={this.handleUploadVideo}
            onDeleteFile={this.handleDeleteVideo}
            imgWidth="1000px"
            imgHeight="500px"
            isVideo
          />
        </Col>
        </Row>
        {/* <SaveCancelBtn
          saveCallback={this.handleSaveSection}
          cancelCallback={this.handleCancel}
          disabled={!this.validateForm() || disabled}
        /> */}
      </div>
    );
  }
}

export default withVideo(HomeVideo);
