import gql from 'graphql-tag';

export default gql`
  mutation setUserStatus($token: String!, $id: String!, $status: String!) {
    setUserStatus(token: $token, id: $id, status: $status) {
      id
      status
    }
  }
`;
