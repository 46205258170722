import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, Pagination } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const type = 'DragbleBodyRow';

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item) => {
      moveRow && moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const components = {
  body: {
    row: DragableBodyRow
  }
};

class TableWithOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      loading: props.loading,
      currentPage: 1,
      itemPerPage: 5
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, loading, deleted } = nextProps;
    if ((prevState.loading && data !== prevState.data) || deleted) {
      return {
        data,
        loading,
        currentPage:
          _.isEmpty(data) && deleted && prevState.currentPage > 1
            ? prevState.currentPage - 1
            : prevState.currentPage
      };
    }
    return null;
  }

  handleChangePage = (currentPage) => {
    this.setState(
      {
        currentPage,
        loading: true
      },
      () => {
        this.props.callbackChangePage &&
          this.props.callbackChangePage({
            itemPerPage: this.state.itemPerPage,
            currentPage
          });
      }
    );
  };

  handleChangeTotalCount = (_, itemPerPage) => {
    this.setState(
      {
        itemPerPage,
        currentPage: 1,
        loading: true
      },
      () => {
        this.props.callbackChangePage &&
          this.props.callbackChangePage({
            itemPerPage,
            currentPage: 1
          });
      }
    );
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { callbackChangeOrder, sortingKey } = this.props;
    const { currentPage, itemPerPage } = this.state;
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }
      }),
      () => {
        const clonedData = this.state.data.map((item) => item);
        const actualOrder = currentPage * itemPerPage - itemPerPage;

        clonedData.forEach((item, index) => {
          item[sortingKey] = index + 1;
          callbackChangeOrder &&
            callbackChangeOrder(item, actualOrder + index + 1);
        });
        this.setState({
          data: clonedData
        });
      }
    );
  };

  render() {
    const { columns, loading, sortingKey, pagination } = this.props;
    const { currentPage, itemPerPage } = this.state;
    const data = _.map(
      sortingKey ? _.sortBy(this.state.data, sortingKey) : this.state.data,
      (item, index) => ({
        ...item,
        index: index + 1
      })
    );
    const { totalCount } = pagination;
    const paginConfig = {
      total: totalCount,
      pageSize: itemPerPage,
      current: currentPage,
      showTotal: (totalCount) => `Total ${totalCount} item(s)`,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '20', '50', '100'],
      onShowSizeChange: this.handleChangeTotalCount
    };

    return (
      !loading && (
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={data}
            columns={columns}
            components={components}
            rowKey="id"
            onRow={(_, index) => ({
              index,
              moveRow: this.moveRow
            })}
            pagination={false}
          />
          {!_.isEmpty(pagination) && (
            <Pagination
              onChange={this.handleChangePage}
              {...paginConfig}
              className="custom-pagination"
            />
          )}
        </DndProvider>
      )
    );
  }
}

TableWithOrder.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  deleted: PropTypes.bool,
  callbackChangePage: PropTypes.func,
  callbackChangeOrder: PropTypes.func,
  sortingKey: PropTypes.string,
  pagination: PropTypes.shape({})
};

TableWithOrder.defaultProps = {
  deleted: false,
  callbackChangePage: () => {},
  callbackChangeOrder: () => {},
  sortingKey: '',
  pagination: {}
};

export default TableWithOrder;
