import React from 'react';
import { Input, Button, Form } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login } from 'gql/mutation';
import { errorModal } from 'components/Modal';
import { handleError } from 'utils/utils';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  hanldeChangeValue = (field, e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  };

  handleLogin = () => {
    const { username, password } = this.state;
    this.props.client
      .mutate({
        mutation: login,
        variables: {
          username,
          password
        }
      })
      .then(({ data }) => {
        if (data.login && data.login.token) {
          if (data.login.status === 'active') {
            try {
              const decodeToken = JSON.parse(
                window.atob(data.login.token.split('.')[1])
              );
              sessionStorage.setItem('token', data.login.token);
              sessionStorage.setItem('userId', decodeToken.userId);
              sessionStorage.setItem('username', decodeToken.username);
              this.props.history.push('/');
            } catch (err) {
              errorModal(err);
            }
          } else {
            errorModal(
              'User has been block, please contact admin to get more information!'
            );
          }
        }
      })
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  render() {
    const { username, password } = this.state;
    return (
      <div className="form-login">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true
          }}
        >
          <h1 className="title">Login</h1>
          <Form.Item
            name="username"
            rule={[{ require: true, message: 'Please input your username!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              value={username}
              onChange={(e) => this.hanldeChangeValue('username', e)}
              onKeyDown={this.handleEnter}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rule={[{ require: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => this.hanldeChangeValue('password', e)}
              onKeyDown={this.handleEnter}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              type="primary"
              onClick={this.handleLogin}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default LoginPage;
