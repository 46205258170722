import gql from 'graphql-tag';

export default gql`
  mutation addVideo($id: String!, $videoLink: String!) {
    addVideo(id: $id, videoLink: $videoLink) {
      id
      imgUrl
    }
  }
`;
