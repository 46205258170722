import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import SaveCancelBtn from 'components/SaveCancelBtn';
import UploadFile from 'components/UploadFile';
import { successModal, errorModal } from 'components/Modal';
import { handleMutation, handleError } from 'utils/utils';

class LinkDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: '',
      loading: true,
      disabled: true
    };
  }

  componentDidMount() {
    const {
      match: { params },
      fetchQuery,
      queryKey
    } = this.props;
    if (params['id']) {
      this.props.client
        .query({
          query: fetchQuery,
          variables: {
            id: params['id']
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          if (data && data[queryKey]) {
            this.setState({
              imgUrl: data[queryKey].imgUrl,
              loading: false,
              disabled: !!data[queryKey].imgUrl
            });
          }
        })
        .catch((err) => {
          errorModal(handleError(err, 'graphQLErrors'));
        });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: { params }
    } = nextProps;
    if (!params['id'] && prevState.loading) {
      return {
        loading: false
      };
    }
    return null;
  }

  handleUploadImage = (url) => {
    this.setState({
      imgUrl: url,
      disabled: false
    });
  };

  handleSaveImage = () => {
    const {
      match: { params },
      createMutation,
      updateMutation,
      createMutationKey,
      updateMutationKey,
      history,
      listLink
    } = this.props;
    const { imgUrl } = this.state;
    const formData = {
      imgUrl
    };
    let mutation = createMutation;
    let dataKey = createMutationKey;

    if (params['id']) {
      mutation = updateMutation;
      formData.id = params['id'];
      dataKey = updateMutationKey;
    }

    handleMutation(
      this,
      mutation,
      formData,
      dataKey,
      () => {
        successModal('Save client image successfully.', () => {
          history.push(listLink);
        });
      },
      () => {
        errorModal('Fail to save client image!!');
      }
    );
  };

  handleCancel = () => {
    const { listLink } = this.props;
    this.props.history.push(listLink);
  };

  render() {
    const { imgUrl, loading, disabled } = this.state;
    const { imgWidth, imgHeight } = this.props;

    return (
      <>
        <UploadFile
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          url={imgUrl}
          loading={loading}
          onUploadFile={this.handleUploadImage}
        />
        <SaveCancelBtn
          saveCallback={this.handleSaveImage}
          cancelCallback={this.handleCancel}
          disabled={disabled}
        />
      </>
    );
  }
}

LinkDetail.propTypes = {
  fetchQuery: PropTypes.any.isRequired,
  queryKey: PropTypes.string.isRequired,
  createMutation: PropTypes.any.isRequired,
  updateMutation: PropTypes.any.isRequired,
  createMutationKey: PropTypes.string.isRequired,
  updateMutationKey: PropTypes.string.isRequired,
  listLink: PropTypes.string.isRequired,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string
};

LinkDetail.defaultProps = {
  imgWidth: '148px',
  imgHeight: '148px'
};

export default withApollo(withRouter(LinkDetail));
