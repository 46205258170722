import React from 'react';
import { flowRight as compose, isEmpty } from 'lodash';
import { graphql } from 'react-apollo';
import { Checkbox, Row, Col, Button } from 'antd';
import CustomInput from 'components/CustomInput';
import UploadFile from 'components/UploadFile';
import SaveCancelBtn from 'components/SaveCancelBtn';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import ImageLoader from 'components/ImageLoader';
import { successModal, errorModal } from 'components/Modal';
import {
  addEvent,
  updateEvent,
  deleteEventContent,
  updateEventContent
} from 'gql/mutation';
import { singleEvent, eventContents } from 'gql/query';
import { handleMutation, handleDeleteItem, handleError } from 'utils/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/constants';

const withEvent = graphql(singleEvent, {
  props: ({ data, ownProps }) => {
    return ownProps.match.params['eventId'] && data
      ? {
          event: data.singleEventAdmin ? data.singleEventAdmin : {},
          loading: data.loading
        }
      : {
          event: {}
        };
  },
  options: ({ match, location }) => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: match.params['eventId'] || ''
      },
      onError: (err) => {
        if (!location.pathname.includes('create')) {
          errorModal(handleError(err, 'graphQLErrors'));
        }
      }
    };
  }
});
const withEventContents = graphql(eventContents, {
  props: ({ data, ownProps }) => {
    return ownProps.match.params['eventId'] && data
      ? {
          contents:
            data.eventsContentAdmin && data.eventsContentAdmin.data
              ? data.eventsContentAdmin.data
              : [],
          pagination:
            data.eventsContentAdmin && data.eventsContentAdmin.pagination
              ? data.eventsContentAdmin.pagination
              : {},
          loading: data.loading,
          refetchEventContentList: (params) => {
            return data.refetch(params);
          }
        }
      : {
          refetchEventContentList: () => {},
          contents: [],
          pagination: {}
        };
  },
  options: ({ match }) => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        eventId: match.params['eventId'] || '',
        sort: {
          sortKey: 'orders',
          sortType: 'asc'
        }
      },
      onError: (err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      }
    };
  }
});

const withData = compose(withEvent, withEventContents);

class AddEditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      client: '',
      brand: '',
      category: '',
      projectType: '',
      year: '',
      showInHome: false,
      fileName: '',
      urlImg: '',
      loading: props.loading,
      disabledSave: true,
      deleted: false,
      pagination: {
        currentPage: DEFAULT_PAGE,
        itemPerPage: DEFAULT_PAGE_SIZE
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { event, loading } = nextProps;

    if (prevState.loading && event) {
      return {
        ...event,
        loading
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      contents,
      match: { params }
    } = this.props;
    const { pagination } = prevState;
    if (isEmpty(contents) && contents !== prevProps.contents) {
      this.props.refetchEventContentList({
        eventId: params['eventId'],
        ...pagination,
        currentPage: pagination.currentPage > 1 ? pagination.currentPage - 1 : 1
      });
    }
  }

  handleChange = (e, field) => {
    const { value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        disabledSave: false,
        [field]: value
      };
    });
  };

  handleOnCheck = (e) => {
    this.setState({
      showInHome: e.target.checked,
      disabledSave: false
    });
  };

  handleUploadImage = (url) => {
    this.setState({
      urlImg: url,
      disabledSave: false
    });
  };

  handleMoveToEventContentPage = () => {
    const {
      match: { params },
      location: { state }
    } = this.props;
    this.props.history.push({
      pathname: `/section/${params['id']}/event/${params['eventId']}/content/create`,
      state: {
        prevPath: state && state.prevPath ? state.prevPath : '/event',
        eventSectionId:
          state && state.eventSectionId ? state.eventSectionId : params['id']
      }
    });
  };

  validateForm = () => {
    const {
      title,
      description,
      client,
      brand,
      category,
      projectType,
      year,
      showInHome,
      urlImg
    } = this.state;

    if (
      title &&
      description &&
      client &&
      brand &&
      category &&
      projectType &&
      year
    ) {
      return {
        title,
        description,
        client,
        brand,
        category,
        projectType,
        year,
        showInHome,
        urlImg
      };
    }
    return false;
  };

  handleSaveEvent = () => {
    const {
      match: { params },
      location: { state },
      history
    } = this.props;
    if (this.validateForm()) {
      let formData = {
        ...this.validateForm(),
        eventSectionId: params['id']
      };
      let dataKey = 'addEvent';
      let mutation = addEvent;
      if (params['eventId']) {
        mutation = updateEvent;
        formData = {
          ...this.validateForm(),
          id: params['eventId'],
          eventSectionId: ''
        };
        dataKey = 'updateEvent';
      }

      handleMutation(
        this,
        mutation,
        formData,
        dataKey,
        () => {
          successModal('Save Event successfully.', () => {
            history.push({
              pathname: `/event`,
              state: {
                eventSectionId: state.eventSectionId ? state.eventSectionId : ''
              }
            });
          });
        },
        () => {
          errorModal('Fail to save Event!!');
        }
      );
    }
  };

  handleChangeOrder = (row, order) => {
    this.props.client
      .mutate({
        mutation: updateEventContent,
        variables: {
          ...row,
          orders: order
        }
      })
      .then(() => {})
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  handleCancel = () => {
    const {
      location: { state },
      match: { params }
    } = this.props;
    if (state && state.prevPath) {
      this.props.history.push({
        pathname: state.prevPath,
        state: {
          eventSectionId:
            state && state.eventSectionId ? state.eventSectionId : params['id']
        }
      });
    } else {
      this.props.push({
        pathname: '/event',
        state: {
          eventSectionId: state.eventSectionId
            ? state && state.eventSectionId
            : params['id']
        }
      });
    }
  };

  renderTableColumn = () => {
    const {
      match: { params },
      location: { state }
    } = this.props;
    const { pagination } = this.state;

    return [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Preview',
        render: (item) => {
          const { urlContent, type } = item;
          return type === 'image' ? (
            <ImageLoader url={urlContent} imgClass="content-image" />
          ) : (
            <a target="_blank" rel="noopener noreferrer" href={urlContent}>
              {urlContent}
            </a>
          );
        }
      },
      {
        title: 'Order',
        dataIndex: 'index',
        key: 'index'
      },
      {
        render: ({ id }) => {
          return (
            id && (
              <EditDeleteBtn
                link={`/section/${params['id']}/event/${params['eventId']}/content/${id}/edit`}
                routeState={{
                  prevPath: state && state.prevPath ? state.prevPath : '/event',
                  eventSectionId:
                    state && state.eventSectionId
                      ? state.eventSectionId
                      : params['id']
                }}
                itemId={id}
                deleteMutation={deleteEventContent}
                refresh={() =>
                  handleDeleteItem(
                    this,
                    this.props.refetchEventContentList({
                      eventId: params['eventId'],
                      ...pagination
                    })
                  )
                }
              />
            )
          );
        }
      }
    ];
  };

  callbackChangePage = (pagin) => {
    const {
      match: { params }
    } = this.props;
    this.setState({
      pagination: {
        currentPage: pagin.currentPage,
        itemPerPage: pagin.itemPerPage
      }
    });
    this.props.refetchEventContentList({
      eventId: params['eventId'],
      ...pagin
    });
  };

  render() {
    const {
      match: { params },
      contents,
      pagination
    } = this.props;
    const {
      title,
      description,
      client,
      brand,
      category,
      projectType,
      year,
      showInHome,
      urlImg,
      loading,
      disabledSave,
      deleted
    } = this.state;

    return (
      <div className="event-page">
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <CustomInput
              label="Event Title"
              value={title}
              onChange={(e) => this.handleChange(e, 'title')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Client"
              value={client}
              onChange={(e) => this.handleChange(e, 'client')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Brand"
              value={brand}
              onChange={(e) => this.handleChange(e, 'brand')}
            />
          </Col>
          <Col span={24}>
            <CustomInput
              rows={2}
              type="textarea"
              label="Event Description"
              value={description}
              onChange={(e) => this.handleChange(e, 'description')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Category"
              value={category}
              onChange={(e) => this.handleChange(e, 'category')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Project Type"
              value={projectType}
              onChange={(e) => this.handleChange(e, 'projectType')}
            />
          </Col>
          <Col span={8}>
            <CustomInput
              label="Year"
              value={year}
              onChange={(e) => this.handleChange(e, 'year')}
            />
          </Col>
          <Col span={24}>
            <Checkbox checked={showInHome} onChange={this.handleOnCheck}>
              Show in home
            </Checkbox>
          </Col>
          <Col span={24}>
            <UploadFile
              url={urlImg}
              loading={loading}
              onUploadFile={this.handleUploadImage}
            />
          </Col>
        </Row>
        <SaveCancelBtn
          saveCallback={this.handleSaveEvent}
          cancelCallback={this.handleCancel}
          disabled={!this.validateForm() || disabledSave}
        />
        {params['eventId'] && (
          <>
            <div className="add-button">
              <Button
                type="primary"
                onClick={this.handleMoveToEventContentPage}
              >
                Add Event Image
              </Button>
            </div>
            <div className="content-table">
              <TableWithOrder
                columns={this.renderTableColumn()}
                data={contents}
                loading={this.props.loading}
                sortingKey="orders"
                callbackChangeOrder={this.handleChangeOrder}
                deleted={deleted}
                pagination={pagination}
                callbackChangePage={this.callbackChangePage}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withData(AddEditEvent);
