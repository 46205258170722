import gql from 'graphql-tag';

export default gql`
  mutation addFeedback(
    $clientName: String!
    $clientPosition: String!
    $operator: String!
    $description: String!
    $urlImg: String!
  ) {
    addFeedback(
      clientName: $clientName
      clientPosition: $clientPosition
      operator: $operator
      description: $description
      urlImg: $urlImg
    ) {
      id
    }
  }
`;
