import React from 'react';
import { graphql } from 'react-apollo';
import { Radio } from 'antd';
import Youtube from 'react-youtube';
import CustomInput from 'components/CustomInput';
import UploadFile from 'components/UploadFile';
import SaveCancelBtn from 'components/SaveCancelBtn';
import { successModal, errorModal } from 'components/Modal';
import { singleEventContent } from 'gql/query';
import { addEventContent, updateEventContent } from 'gql/mutation';
import { handleMutation, handleError } from 'utils/utils';

const withContent = graphql(singleEventContent, {
  props: ({ data, ownProps }) => {
    return ownProps.match.params['contentId'] && data
      ? {
          eventContent: data.eventContentAdmin ? data.eventContentAdmin : {},
          loading: data.loading
        }
      : {};
  },
  options: ({ match, location }) => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: match.params['contentId'] || ''
      },
      onError: (err) => {
        if (!location.pathname.includes('create')) {
          errorModal(handleError(err, 'graphQLErrors'));
        }
      }
    };
  }
});

class AddEditEventContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'image',
      urlContent: '',
      imgUrl: '',
      videoLink: '',
      orders: 1,
      loading: props.loading,
      disabled: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, eventContent } = nextProps;
    if (prevState.loading) {
      const { type, urlContent, orders } = eventContent;
      return {
        type,
        imgUrl: type === 'image' ? urlContent : '',
        videoLink: type === 'video' ? urlContent : '',
        orders,
        loading
      };
    }
    return null;
  }

  handleChangeContent = (e) => {
    const { value } = e.target;
    this.setState(
      {
        type: value
      },
      () => {
        const { eventContent } = this.props;
        switch (value) {
          case 'image':
            this.setState((prevState) => {
              return {
                imgUrl: prevState.imgUrl
                  ? prevState.imgUrl
                  : eventContent && eventContent.type === 'image'
                  ? eventContent.urlContent
                  : prevState.imgUrl
              };
            });
            break;
          case 'video':
            this.setState((prevState) => {
              return {
                videoLink: prevState.imgUrl
                  ? prevState.videoLink
                  : eventContent && eventContent.type === 'video'
                  ? eventContent.urlContent
                  : prevState.videoLink
              };
            });
            break;
          default:
            break;
        }
      }
    );
  };

  handleUploadImage = (url) => {
    this.setState({
      imgUrl: url,
      disabled: false
    });
  };

  handleChangeVideoLink = (e) => {
    const { value } = e.target;
    this.setState({
      videoLink: value.trim(),
      disabled: false
    });
  };

  handleSaveSection = () => {
    const {
      match: { params },
      history,
      location: { state }
    } = this.props;
    const { type, videoLink, imgUrl } = this.state;

    let mutation = addEventContent;
    let formData = {
      eventId: params['eventId'],
      type,
      urlContent: type === 'image' ? imgUrl : videoLink
    };
    let dataKey = 'addEventContent';

    if (params['contentId']) {
      mutation = updateEventContent;
      formData.id = params['contentId'];
      dataKey = 'updateEventContent';
    }

    handleMutation(
      this,
      mutation,
      formData,
      dataKey,
      () => {
        successModal('Save content successfully.', () => {
          history.push({
            pathname: `/section/${params['id']}/event/${params['eventId']}`,
            state: {
              prevPath: state && state.prevPath ? state.prevPath : '/event',
              eventSectionId:
                state && state.eventSectionId
                  ? state.eventSectionId
                  : params['id']
            }
          });
        });
      },
      () => {
        errorModal('Fail to save content!!');
      }
    );
  };

  handleCancel = () => {
    const {
      history,
      match: { params },
      location: { state }
    } = this.props;
    history.push({
      pathname: `/section/${params['id']}/event/${params['eventId']}`,
      state: {
        prevPath: state && state.prevPath ? state.prevPath : '/event',
        eventSectionId:
          state && state.eventSectionId ? state.eventSectionId : params['id']
      }
    });
  };

  renderContent = () => {
    const { type, imgUrl, videoLink, loading } = this.state;
    switch (type) {
      case 'image':
        return (
          <UploadFile
            url={imgUrl}
            loading={loading}
            onUploadFile={this.handleUploadImage}
            imgWidth="940px"
            imgHeight="500px"
          />
        );
      case 'video':
        const options = {
          height: '100%',
          width: '100%'
        };
        const videoId = videoLink.includes('embed')
          ? videoLink.split('https://www.youtube.com/embed/')[1]
          : videoLink.split('https://www.youtube.com/watch?v=')[1];

        return (
          <>
            <CustomInput
              value={videoLink}
              onChange={this.handleChangeVideoLink}
              label="Youtube link"
            />
            {videoId && (
              <Youtube
                videoId={videoId}
                containerClassName="iframe-loader"
                opts={options}
              />
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    const { type, disabled } = this.state;
    return (
      <div className="add-edit-event-content-page">
        <Radio.Group
          name="radiogroup"
          value={type}
          onChange={this.handleChangeContent}
        >
          <Radio value="image">Image Content</Radio>
          <Radio value="video">Youtube Content</Radio>
        </Radio.Group>
        {this.renderContent()}
        <SaveCancelBtn
          cancelCallback={this.handleCancel}
          saveCallback={this.handleSaveSection}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default withContent(AddEditEventContent);
