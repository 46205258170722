import gql from 'graphql-tag';

export default gql`
  query feedbacks($itemPerPage: Int, $currentPage: Int, $sort: Sort) {
    feedbacks(
      itemPerPage: $itemPerPage
      currentPage: $currentPage
      sort: $sort
    ) {
      data {
        id
        clientName
        clientPosition
        operator
        description
        urlImg
      }
      pagination {
        totalCount
        itemPerPage
        currentPage
      }
    }
  }
`;
