import gql from 'graphql-tag';

export default gql`
  mutation addEventSection(
    $title: String!
    $subTitle: String!
    $description: String!
  ) {
    addEventSection(
      title: $title
      subTitle: $subTitle
      description: $description
    ) {
      id
    }
  }
`;
