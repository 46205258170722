import gql from 'graphql-tag';

export default gql`
  mutation changePassword(
    $id: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`;
