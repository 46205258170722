import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { Button } from 'antd';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { ConfirmModal, errorModal } from 'components/Modal';
import { handleError } from 'utils/utils';

class EditDeleteBtn extends React.Component {
  handleDelete = () => {
    const { deleteMutation, itemId, refresh, notShow } = this.props;
    if (!notShow) {
      ConfirmModal('Are you sure to delete?', () => {
        this.props.client
          .mutate({
            mutation: deleteMutation,
            variables: { id: itemId }
          })
          .then((data) => {
            if (data) {
              refresh();
            }
          })
          .catch((err) => {
            errorModal(handleError(err, 'graphQLErrors'));
          });
      });
    }
  };

  render() {
    const { link, notShow, routeState } = this.props;
    const to = {
      pathname: link
    };
    if (routeState && !_.isEmpty(routeState)) {
      to.state = routeState;
    }
    return (
      <div className="edit-del-btn">
        <Button type="primary">
          <Link to={to}>
            <FormOutlined />
          </Link>
        </Button>
        {!notShow && (
          <Button type="primary" danger onClick={this.handleDelete}>
            <DeleteOutlined />
          </Button>
        )}
      </div>
    );
  }
}

EditDeleteBtn.propTypes = {
  link: PropTypes.string.isRequired,
  notShow: PropTypes.bool,
  routeState: PropTypes.shape({}),
  deleteMutation: PropTypes.any,
  itemId: PropTypes.string,
  refresh: PropTypes.func
};

EditDeleteBtn.defaultProps = {
  notShow: false,
  routeState: {},
  deleteMutation: '',
  itemId: '',
  refresh: () => {}
};

export default withApollo(withRouter(EditDeleteBtn));
