import gql from 'graphql-tag';

export default gql`
  query eventsAdmin(
    $eventSectionId: String
    $itemPerPage: Int
    $currentPage: Int
    $sort: Sort
  ) {
    eventsAdmin(
      eventSectionId: $eventSectionId
      itemPerPage: $itemPerPage
      currentPage: $currentPage
      sort: $sort
    ) {
      data {
        id
        title
        description
        client
        brand
        category
        projectType
        year
        orderInEvent
        orderSection
      }
      pagination {
        totalCount
        itemPerPage
        currentPage
      }
    }
  }
`;
