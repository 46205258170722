import React from 'react';
import { graphql } from 'react-apollo';
import { Row, Col } from 'antd';
import CustomInput from 'components/CustomInput';
import SaveCancelBtn from 'components/SaveCancelBtn';
import { successModal, errorModal } from 'components/Modal';
import { addEventSection, updateEventSection } from 'gql/mutation';
import { singleEventSection } from 'gql/query';
import { handleMutation, handleError } from 'utils/utils';

const withEventSection = graphql(singleEventSection, {
  props: ({ data, ownProps }) => {
    return ownProps.match.params['id'] && data
      ? {
          eventSection: data.singleEventSectionAdmin
            ? data.singleEventSectionAdmin
            : {},
          loading: data.loading
        }
      : {
          eventSection: {}
        };
  },
  options: ({ match, location }) => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: match.params['id'] || ''
      },
      onError: (err) => {
        if (!location.pathname.includes('create')) {
          errorModal(handleError(err, 'graphQLErrors'));
        }
      }
    };
  }
});

class AddEditEventSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      subTitle: '',
      description: '',
      loading: props.loading,
      disabled: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { eventSection, loading } = nextProps;
    if (prevState.loading && eventSection) {
      const { title, subTitle, description } = eventSection;
      return {
        loading,
        title,
        subTitle,
        description
      };
    }
    return null;
  }

  handleChange = (e, field) => {
    const { value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [field]: value,
        disabled: false
      };
    });
  };

  validateForm = () => {
    const { title, subTitle, description } = this.state;
    if (title) {
      return {
        title,
        subTitle,
        description
      };
    }
    return false;
  };

  handleSaveSection = () => {
    const {
      match: { params },
      history
    } = this.props;

    if (this.validateForm()) {
      let mutation = addEventSection;
      let formData = this.validateForm();
      let dataKey = 'addEventSection';

      if (params['id']) {
        mutation = updateEventSection;
        formData.id = params['id'];
        dataKey = 'updateEventSection';
      }

      handleMutation(
        this,
        mutation,
        formData,
        dataKey,
        () => {
          successModal('Save Section successfully.', () => {
            history.push('/sections');
          });
        },
        () => {
          errorModal('Fail to save Section!!');
        }
      );
    }
  };

  handleCancel = () => {
    this.props.history.push('/sections');
  };

  render() {
    const { title, subTitle, description, disabled } = this.state;

    return (
      <div className="event-section-detail">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <CustomInput
              label="Title"
              value={title}
              onChange={(e) => this.handleChange(e, 'title')}
            />
          </Col>
          <Col span={12}>
            <CustomInput
              label="Sub-Title"
              value={subTitle}
              onChange={(e) => this.handleChange(e, 'subTitle')}
            />
          </Col>
          <Col span={24}>
            <CustomInput
              rows={8}
              type="textarea"
              label="Description"
              value={description}
              onChange={(e) => this.handleChange(e, 'description')}
            />
          </Col>
        </Row>
        <SaveCancelBtn
          cancelCallback={this.handleCancel}
          saveCallback={this.handleSaveSection}
          disabled={!this.validateForm() || disabled}
        />
      </div>
    );
  }
}

export default withEventSection(AddEditEventSection);
