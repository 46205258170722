import gql from 'graphql-tag';

export default gql`
  mutation updateEvent(
    $id: String!
    $title: String!
    $description: String!
    $urlImg: String
    $client: String
    $brand: String
    $category: String
    $projectType: String
    $year: String
    $orderInHome: Int
    $orderInEvent: Int
    $orderSection: Int
    $showInHome: Boolean
  ) {
    updateEvent(
      id: $id
      title: $title
      description: $description
      urlImg: $urlImg
      client: $client
      brand: $brand
      category: $category
      projectType: $projectType
      year: $year
      orderInHome: $orderInHome
      orderInEvent: $orderInEvent
      orderSection: $orderSection
      showInHome: $showInHome
    ) {
      id
    }
  }
`;
