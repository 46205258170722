import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { graphql } from 'react-apollo';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import { eventSections } from 'gql/query';
import { updateEventSection, deleteEventSection } from 'gql/mutation';
import { errorModal } from 'components/Modal';
import { handleDeleteItem, handleError } from 'utils/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/constants';

const withEventSection = graphql(eventSections, {
  props: ({ data }) => {
    return data
      ? {
          eventSections:
            data.eventSectionsAdmin && data.eventSectionsAdmin.data
              ? data.eventSectionsAdmin.data
              : [],
          pagination:
            data.eventSectionsAdmin && data.eventSectionsAdmin.pagination
              ? data.eventSectionsAdmin.pagination
              : {},
          loading: data.loading,
          refetchEventSections: (params) => {
            return data.refetch(params);
          }
        }
      : {
          eventSections: [],
          pagination: {},
          refetchEventSections: () => {}
        };
  },
  options: () => {
    return {
      fetchPolicy: 'network-only',
      sort: {
        sortKey: 'orders',
        sortType: 'asc'
      }
    };
  }
});

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
      pagination: {
        currentPage: DEFAULT_PAGE,
        itemPerPage: DEFAULT_PAGE_SIZE
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { eventSections } = this.props;
    const { pagination } = prevState;
    if (_.isEmpty(eventSections) && eventSections !== prevProps.eventSections) {
      this.props.refetchEventSections({
        ...pagination,
        currentPage: pagination.currentPage > 1 ? pagination.currentPage - 1 : 1
      });
    }
  }

  renderTableColumn = () => {
    const { pagination } = this.state;
    return [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Sub-Title',
        dataIndex: 'subTitle',
        key: 'subTitle'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
      },
      {
        title: 'Order',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: ({ id }) => (
          <EditDeleteBtn
            link={`/sections/edit/${id}`}
            itemId={id}
            deleteMutation={deleteEventSection}
            refresh={() =>
              handleDeleteItem(
                this,
                this.props.refetchEventSections(pagination)
              )
            }
          />
        )
      }
    ];
  };

  handleChangeOrder = (row, order) => {
    this.props.client
      .mutate({
        mutation: updateEventSection,
        variables: {
          ...row,
          orders: order
        }
      })
      .then(() => {})
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  callbackChangePage = (pagin) => {
    this.setState({
      pagination: {
        currentPage: pagin.currentPage,
        itemPerPage: pagin.itemPerPage
      }
    });
    this.props.refetchEventSections(pagin);
  };

  render() {
    const { history, eventSections, loading, pagination } = this.props;
    const { deleted } = this.state;

    return (
      <div className="event-section">
        <div className="add-button">
          <Button
            type="primary"
            onClick={() => history.push('/sections/create')}
          >
            Add section
          </Button>
        </div>
        <TableWithOrder
          columns={this.renderTableColumn()}
          data={eventSections}
          loading={loading}
          deleted={deleted}
          callbackChangeOrder={this.handleChangeOrder}
          sortingKey="orders"
          pagination={pagination}
          callbackChangePage={this.callbackChangePage}
        />
      </div>
    );
  }
}

export default withEventSection(HomePage);
