import Swal from 'sweetalert2';

export const loadingModal = () => {
  Swal.fire({
    html: `PROCESSING...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
    showConfirmButton: false,
    allowOutsideClick: false
  });
};

export const successModal = (msg, callback = () => {}) => {
  Swal.fire({
    icon: 'success',
    html: msg,
    confirmButtonText: 'OK'
  }).then(() => {
    callback();
  });
};

export const errorModal = (msg, callback = () => {}) => {
  Swal.fire({
    icon: 'error',
    html: msg,
    confirmButtonText: 'CLOSE'
  }).then(() => {
    callback();
  });
};

export const ConfirmModal = (msg, callback = () => {}) => {
  Swal.fire({
    icon: 'warning',
    html: msg,
    showCancelButton: true,
    confirmButtonText: 'YES',
    cancelButtonText: 'NO',
    allowOutsideClick: false
  }).then((res) => {
    if (res.value) {
      callback();
    }
  });
};
