import React from 'react';
import { Button } from 'antd';
import { graphql } from 'react-apollo';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import { errorModal } from 'components/Modal';
import { feedbacks } from 'gql/query';
import { deleteFeedback } from 'gql/mutation';
import { handleDeleteItem, handleError } from 'utils/utils';

const withFeedbacks = graphql(feedbacks, {
  props: ({ data }) => {
    return data
      ? {
          feedbackList:
            data.feedbacks && data.feedbacks.data ? data.feedbacks.data : [],
          loading: data.loading,
          refetchFeedbackList: (params) => {
            return data.refetch(params);
          }
        }
      : {
          feedbackList: [],
          refetchFeedbackList: () => {
            return data.refetch();
          }
        };
  },
  options: () => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onError: (err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      }
    };
  }
});

class ClientFeedbackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: false
    };
  }

  handleChangeContent = (_, editor) => {
    const data = editor.getData();
    this.setState({
      content: data
    });
  };

  renderTableColumn = () => {
    return [
      {
        title: 'Client Name',
        dataIndex: 'clientName',
        key: 'clientName'
      },
      {
        title: 'Position',
        dataIndex: 'clientPosition',
        key: 'clientPosition'
      },
      {
        title: 'Operator',
        dataIndex: 'operator',
        key: 'operator'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => {
          return <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>;
        }
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: ({ id }) => (
          <EditDeleteBtn
            link={`/feedback/edit/${id}`}
            itemId={id}
            deleteMutation={deleteFeedback}
            refresh={() =>
              handleDeleteItem(this, this.props.refetchFeedbackList())
            }
          />
        )
      }
    ];
  };

  render() {
    const { feedbackList, loading, history } = this.props;

    return (
      <div className="feedback-page">
        <div className="add-button">
          <Button
            type="primary"
            onClick={() =>
              feedbackList && feedbackList.length < 5
                ? history.push('/feedback/create')
                : () => {}
            }
            disabled={feedbackList && feedbackList.length >= 5}
          >
            Add Client Feedback
          </Button>
        </div>
        <TableWithOrder
          columns={this.renderTableColumn()}
          data={feedbackList}
          loading={loading}
          deleted={this.state.deleted}
          callback={null}
          sortingKey="orders"
        />
      </div>
    );
  }
}

export default withFeedbacks(ClientFeedbackPage);
