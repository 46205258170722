import React from 'react';
import { Input } from 'antd';

export default ({ label, value, onChange, type = 'text', ...rest }) => {
  const renderInputByType = () => {
    switch (type) {
      case 'text':
        return <Input value={value} onChange={onChange} {...rest} />;
      case 'password':
        return <Input.Password value={value} onChange={onChange} {...rest} />;
      case 'textarea':
        return <Input.TextArea value={value} onChange={onChange} {...rest} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="custom-input">
      <label>{label}</label>
      {renderInputByType()}
    </div>
  );
};
