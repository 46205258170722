import React from 'react';
import { Row, Col } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { graphql } from 'react-apollo';
import CustomInput from 'components/CustomInput';
import SaveCancelBtn from 'components/SaveCancelBtn';
import { successModal, errorModal } from 'components/Modal';
import { addCareer, updateCareer } from 'gql/mutation';
import { singleCareer } from 'gql/query';
import { handleMutation, handleError } from 'utils/utils';

const withCareer = graphql(singleCareer, {
  props: ({ data, ownProps }) => {
    return ownProps.match.params['id'] && data
      ? {
          career: data.singleAdminCareer ? data.singleAdminCareer : {},
          loading: data.loading,
          refetchCareer: () => {
            return data.refetch();
          }
        }
      : {
          refetchCareer: () => {}
        };
  },
  options: ({ match, location }) => {
    return {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        id: match.params['id'] || ''
      },
      onError: (err) => {
        if (!location.pathname.includes('create')) {
          errorModal(handleError(err, 'graphQLErrors'));
        }
      }
    };
  }
});

class AddEditCareerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      loading: props.loading,
      disabled: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { career, loading } = nextProps;

    if (prevState.loading && career) {
      return {
        ...career,
        loading
      };
    }
    return null;
  }

  handleChangeTitle = (e) => {
    const { value } = e.target;
    this.setState({
      title: value,
      disabled: false
    });
  };

  handleChangeContent = (_, editor) => {
    const data = editor.getData();
    this.setState((prevState) => {
      return {
        content: data,
        disabled: !(data !== prevState.content)
      };
    });
  };

  validateForm = () => {
    const { title, content } = this.state;
    if (title && content) {
      return {
        title,
        content
      };
    }
    return false;
  };

  handleSaveCareer = () => {
    const {
      match: { params },
      history
    } = this.props;

    if (this.validateForm()) {
      let mutation = addCareer;
      const formData = this.validateForm();
      let dataKey = 'addCareer';
      if (params['id']) {
        mutation = updateCareer;
        formData.id = params['id'];
        dataKey = 'updateCareer';
      }

      handleMutation(
        this,
        mutation,
        formData,
        dataKey,
        () => {
          successModal('Save career successfully.', () => {
            history.push('/career');
          });
        },
        () => {
          errorModal('Fail to save career!!!');
        }
      );
    }
  };

  handleCancel = () => {
    this.props.history.push('/career');
  };

  render() {
    const { title, content, disabled } = this.state;
    const config = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'numberedlist',
          'bulletedlist',
          'indent',
          'outdent',
          '|',
          'undo',
          'redo'
        ]
      }
    };

    return (
      <div className="career-page">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <CustomInput
              label="Career Title"
              value={title}
              onChange={this.handleChangeTitle}
            />
          </Col>
          <Col span={24}>
            <CKEditor
              editor={ClassicEditor}
              data={content}
              config={config}
              onChange={this.handleChangeContent}
            />
          </Col>
        </Row>
        <SaveCancelBtn
          saveCallback={this.handleSaveCareer}
          cancelCallback={this.handleCancel}
          disabled={!this.validateForm() || disabled}
        />
      </div>
    );
  }
}

export default withCareer(AddEditCareerPage);
