import React from 'react';

import { updateAward, deleteAward } from 'gql/mutation';
import { awards } from 'gql/query';

import ImageLinkList from './ImageLinkList';

class AwardPage extends React.PureComponent {
  render() {
    return (
      <div className="award-page">
        <ImageLinkList
          fetchQuery={awards}
          queryKey="awards"
          updateMutation={updateAward}
          deleteMutation={deleteAward}
          createLink="/awards/create"
          editLink="/awards/edit"
          addBtnText="Add Award"
          imgClass="award-img"
        />
      </div>
    );
  }
}

export default AwardPage;
