import React from 'react';
import { addClient, updateClient } from 'gql/mutation';
import { singleClient } from 'gql/query';
import LinkDetail from './LinkDetail';

class AddEditClientPage extends React.PureComponent {
  render() {
    return (
      <div className="add-edit-client">
        <LinkDetail
          imgWidth="248px"
          imgHeight="248px"
          fetchQuery={singleClient}
          queryKey="singleAdminClient"
          createMutation={addClient}
          updateMutation={updateClient}
          createMutationKey="addClient"
          updateMutationKey="updateClient"
          listLink="/clients"
        />
      </div>
    );
  }
}

export default AddEditClientPage;
