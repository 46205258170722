import gql from 'graphql-tag';

export default gql`
  mutation updateEventSection(
    $id: String!
    $title: String!
    $subTitle: String!
    $description: String!
    $orders: Int
  ) {
    updateEventSection(
      id: $id
      title: $title
      subTitle: $subTitle
      description: $description
      orders: $orders
    ) {
      id
    }
  }
`;
