import gql from 'graphql-tag';

export default gql`
  query singleEventSectionAdmin($id: String!) {
    singleEventSectionAdmin(id: $id) {
      title
      subTitle
      description
      orders
    }
  }
`;
