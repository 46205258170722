import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ConfirmModal } from './Modal';

export default class SaveCancelBtn extends React.Component {
  onCancel = () => {
    const { cancelCallback } = this.props;
    ConfirmModal('Do you want to leave this page?', () => {
      cancelCallback();
    });
  };

  onSave = () => {
    const { saveCallback } = this.props;
    ConfirmModal('Do you want to save?', () => {
      saveCallback();
    });
  };

  render() {
    return (
      <div className="add-button">
        <Button
          type="primary"
          onClick={this.onSave}
          disabled={this.props.disabled}
        >
          Save
        </Button>
        <Button onClick={this.onCancel}>Cancel</Button>
      </div>
    );
  }
}

SaveCancelBtn.propTypes = {
  cancelCallback: PropTypes.func,
  saveCallback: PropTypes.func,
  disabled: PropTypes.bool
};

SaveCancelBtn.defaultProps = {
  cancelCallback: () => {},
  saveCallback: () => {},
  disabled: false
};
