import gql from 'graphql-tag';

export default gql`
  query eventsInHomeAdmin($itemPerPage: Int, $currentPage: Int, $sort: Sort) {
    eventsInHomeAdmin(
      itemPerPage: $itemPerPage
      currentPage: $currentPage
      sort: $sort
    ) {
      data {
        id
        title
        description
        client
        brand
        category
        projectType
        year
        orderInHome
        orderInEvent
        orderSection
        showInHome
        section {
          id
          title
          subTitle
        }
      }
      pagination {
        totalCount
        itemPerPage
        currentPage
      }
    }
  }
`;
