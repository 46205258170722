import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { graphql } from 'react-apollo';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import { errorModal } from 'components/Modal';
import { careers } from 'gql/query';
import { updateCareer, deleteCareer } from 'gql/mutation';
import { handleDeleteItem, handleError } from 'utils/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/constants';

const withCareers = graphql(careers, {
  props: ({ data }) => {
    return data
      ? {
          careerList:
            data.careers && data.careers.data ? data.careers.data : [],
          loading: data.loading,
          pagination:
            data.careers && data.careers.pagination
              ? data.careers.pagination
              : {},
          refetchCareerList: (params) => {
            return data.refetch(params);
          }
        }
      : {
          careerList: [],
          pagination: {},
          refetchCareerList: () => {}
        };
  },
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        sort: {
          sortKey: 'orders',
          sortType: 'asc'
        }
      }
    };
  }
});

class CareerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
      pagination: {
        currentPage: DEFAULT_PAGE,
        itemPerPage: DEFAULT_PAGE_SIZE
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { careerList } = this.props;
    const { pagination } = prevState;
    if (_.isEmpty(careerList) && careerList !== prevProps.careerList) {
      this.props.refetchCareerList({
        ...pagination,
        currentPage: pagination.currentPage > 1 ? pagination.currentPage - 1 : 1
      });
    }
  }

  handleChangeContent = (_, editor) => {
    const data = editor.getData();
    this.setState({
      content: data
    });
  };

  renderTableColumn = () => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Order',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: ({ id }) => (
          <EditDeleteBtn
            link={`/career/edit/${id}`}
            itemId={id}
            deleteMutation={deleteCareer}
            refresh={() =>
              handleDeleteItem(
                this,
                this.props.refetchCareerList(this.state.pagination)
              )
            }
          />
        )
      }
    ];
  };

  handleChangeOrder = (row, order) => {
    this.props.client
      .mutate({
        mutation: updateCareer,
        variables: {
          ...row,
          orders: order
        }
      })
      .then(() => {})
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  callbackChangePage = (pagin) => {
    this.setState({
      pagination: {
        currentPage: pagin.currentPage,
        itemPerPage: pagin.itemPerPage
      }
    });
    this.props.refetchCareerList(pagin);
  };

  render() {
    const { careerList, loading, history, pagination } = this.props;

    return (
      <div className="career-page">
        <div className="add-button">
          <Button type="primary" onClick={() => history.push('/career/create')}>
            Add Career
          </Button>
        </div>
        <TableWithOrder
          columns={this.renderTableColumn()}
          data={careerList}
          loading={loading}
          deleted={this.state.deleted}
          callbackChangeOrder={this.handleChangeOrder}
          sortingKey="orders"
          pagination={pagination}
          callbackChangePage={this.callbackChangePage}
        />
      </div>
    );
  }
}

export default withCareers(CareerPage);
