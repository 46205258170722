import React from 'react';
import EventSectionPage from 'pages/event/EventSectionPage';
import AddEditEventSection from 'pages/event/AddEditEventSection';
import EventPage from 'pages/event/EventPage';
import AddEditEvent from 'pages/event/AddEditEvent';
import EventInHomePage from 'pages/event/EventInHomePage';
import AddEditEventContent from 'pages/event/AddEditEventContent';

import CareerPage from 'pages/career/CareerPage';
import AddEditCareerPage from 'pages/career/AddEditCareerPage';

import ClientFeedback from 'pages/client-feedback/ClientFeedbackPage';
import AddEditClientFeedback from 'pages/client-feedback/AddEditClientFeedback';

import ClientPage from 'pages/client-award/ClientPage';
import AddEditClientPage from 'pages/client-award/AddEditClientPage';

import AwardPage from 'pages/client-award/AwardPage';
import AddEditAwardPage from 'pages/client-award/AddEditAwardPage';

import ChangePasswordPage from 'pages/ChangePasswordPage';
import HomeVideo from 'pages/HomeVideo';

const Empty = () => <></>;

export default [
  {
    path: '/',
    component: Empty
  },
  {
    path: '/home-video',
    component: HomeVideo
  },
  {
    path: '/sections',
    component: EventSectionPage
  },
  {
    path: '/sections/create',
    component: AddEditEventSection
  },
  {
    path: '/sections/edit/:id',
    component: AddEditEventSection
  },
  {
    path: '/event',
    component: EventPage
  },
  {
    path: '/section/:id/event/create',
    component: AddEditEvent
  },
  {
    path: '/section/:id/event/:eventId',
    component: AddEditEvent
  },
  {
    path: '/event-in-home',
    component: EventInHomePage
  },
  {
    path: '/section/:id/event-in-home',
    component: AddEditEvent
  },
  {
    path: '/section/:id/event-in-home/:eventId',
    component: AddEditEvent
  },
  {
    path: '/section/:id/event/:eventId/content/create',
    component: AddEditEventContent
  },
  {
    path: '/section/:id/event/:eventId/content/:contentId/edit',
    component: AddEditEventContent
  },
  {
    path: '/career',
    component: CareerPage
  },
  {
    path: '/career/create',
    component: AddEditCareerPage
  },
  {
    path: '/career/edit/:id',
    component: AddEditCareerPage
  },
  {
    path: '/feedback',
    component: ClientFeedback
  },
  {
    path: '/feedback/create',
    component: AddEditClientFeedback
  },
  {
    path: '/feedback/edit/:id',
    component: AddEditClientFeedback
  },
  {
    path: '/clients',
    component: ClientPage
  },
  {
    path: '/clients/create',
    component: AddEditClientPage
  },
  {
    path: '/clients/edit/:id',
    component: AddEditClientPage
  },
  {
    path: '/awards',
    component: AwardPage
  },
  {
    path: '/awards/create',
    component: AddEditAwardPage
  },
  {
    path: '/awards/edit/:id',
    component: AddEditAwardPage
  },
  {
    path: '/change-password',
    component: ChangePasswordPage
  }
];
