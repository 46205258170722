import React from 'react';
import PropTypes from 'prop-types';

class ImageLoader extends React.Component {
  img;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  componentDidMount() {
    const { url } = this.props;
    this.createLoader(url);
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.createLoader(this.props.url);
    }
  }

  createLoader = (src) => {
    if (src) {
      this.img = document.createElement('img');
      this.img.onload = this.handleLoadComplete;
      this.img.onerror = this.handleError;
      this.img.src = src;
    } else {
      this.handleError();
    }
  };

  handleLoadComplete = () => {
    this.setState(
      {
        isLoaded: true
      },
      () => {
        this.props.successCallback();
      }
    );
  };

  handleError = () => {
    this.setState(
      {
        isLoaded: false
      },
      () => {
        this.props.errorCallback();
      }
    );
  };

  render() {
    const { isLoaded } = this.state;
    return isLoaded ? (
      <div className={this.props.imgClass}>
        <img src={this.props.url} alt="content" />
      </div>
    ) : (
      <div className="no-image">no image</div>
    );
  }
}

ImageLoader.propTypes = {
  url: PropTypes.string.isRequired,
  imgClass: PropTypes.string,
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func
};

ImageLoader.defaultProps = {
  imgClass: '',
  successCallback: () => {},
  errorCallback: () => {}
};

export default ImageLoader;
