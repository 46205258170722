import React from 'react';
import { isEmpty } from 'lodash';
import { graphql } from 'react-apollo';
import { Button, Radio, Menu } from 'antd';
import TableWithOrder from 'components/TableWithOrder';
import EditDeleteBtn from 'components/EditDeleteBtn';
import { successModal, errorModal } from 'components/Modal';
import { updateEvent, deleteEvent } from 'gql/mutation';
import { events, eventSections } from 'gql/query';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/constants';
import { handleError } from 'utils/utils';

const withEventSection = graphql(eventSections, {
  props: ({ data }) => {
    return data
      ? {
          eventSections:
            data.eventSectionsAdmin && data.eventSectionsAdmin.data
              ? data.eventSectionsAdmin.data
              : []
        }
      : {
          eventSections: []
        };
  },
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        itemPerPage: 100,
        sort: {
          sortKey: 'orders',
          sortType: 'asc'
        }
      },
      onError: (err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      }
    };
  }
});

class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      deleted: false,
      events: [],
      currentTab: '',
      eventSectionId: '',
      currentTable: 'orderInEvent',
      pagination: {
        currentPage: DEFAULT_PAGE,
        itemPerPage: DEFAULT_PAGE_SIZE
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { events, eventSectionId } = this.state;
    const { pagination } = prevState;
    const { eventSections } = this.props;
    if (isEmpty(events) && events !== prevState.events && eventSectionId) {
      this.setState(
        {
          pagination: {
            ...pagination,
            currentPage:
              pagination.currentPage > 1 ? pagination.currentPage - 1 : 1
          }
        },
        () => {
          this.fetchEvents(true);
        }
      );
    }
    if (!isEmpty(eventSections) && eventSections !== prevProps.eventSections) {
      this.mountingEvent();
    }
  }

  mountingEvent = () => {
    const { pagination, currentTable } = this.state;
    const {
      location: { state },
      eventSections
    } = this.props;

    const sectionId =
      state && state.eventSectionId
        ? state.eventSectionId
        : eventSections[0].id;
    this.setState(
      {
        currentTab: sectionId,
        eventSectionId: sectionId
      },
      () => {
        const { eventSectionId } = this.state;
        this.props.client
          .query({
            query: events,
            variables: {
              eventSectionId,
              ...pagination,
              sort: {
                sortKey: currentTable,
                sortType: 'asc'
              }
            },
            fetchPolicy: 'network-only'
          })
          .then(({ data }) => {
            if (data && data.eventsAdmin && data.eventsAdmin.data) {
              this.setState({
                events: data.eventsAdmin.data,
                pagination: data.eventsAdmin.pagination,
                loading: false
              });
            }
          })
          .catch((err) => {
            errorModal(handleError(err, 'graphQLErrors'));
          });
      }
    );
  };

  fetchEvents = (deleted = false, refetchAfterDeleted = false) => {
    const { eventSectionId, pagination, currentTable } = this.state;

    if (eventSectionId) {
      this.props.client
        .query({
          query: events,
          variables: {
            eventSectionId,
            ...pagination,
            sort: {
              sortKey: currentTable,
              sortType: 'asc'
            }
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => {
          if (data && data.eventsAdmin && data.eventsAdmin.data) {
            this.setState({
              events: data.eventsAdmin.data,
              pagination: data.eventsAdmin.pagination,
              loading: false,
              deleted
            });
            if (refetchAfterDeleted) {
              successModal('Delete event successfully.');
            }
          }
        })
        .catch((err) => {
          errorModal(handleError(err, 'graphQLErrors'));
        });
    }
  };

  handleChangeTable = (e) => {
    this.setState(
      {
        currentTable: e.target.value,
        loading: true
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleChangeOrder = (row, order) => {
    const { currentTable } = this.state;
    this.props.client
      .mutate({
        mutation: updateEvent,
        variables: {
          ...row,
          [currentTable]: order
        }
      })
      .then(() => {
        this.fetchEvents();
      })
      .catch((err) => {
        errorModal(handleError(err, 'graphQLErrors'));
      });
  };

  callbackChangePage = (pagin) => {
    this.setState(
      {
        pagination: {
          currentPage: pagin.currentPage,
          itemPerPage: pagin.itemPerPage
        },
        loading: true
      },
      () => this.fetchEvents()
    );
  };

  handleChangeTab = ({ key }) => {
    this.setState(
      {
        currentTab: key,
        eventSectionId: key,
        events: [],
        loading: true
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleMoveToEventPage = () => {
    const { eventSectionId } = this.state;
    this.props.history.push({
      pathname: `/section/${eventSectionId}/event/create`,
      state: { prevPath: '/event', eventSectionId }
    });
  };

  renderTableColumn = () => {
    const { eventSectionId } = this.state;

    return [
      {
        title: 'Event Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Event Description',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client'
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category'
      },
      {
        title: 'Project Type',
        dataIndex: 'projectType',
        key: 'projectType'
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year'
      },
      {
        title: 'Order',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: ({ id }) => {
          return (
            id && (
              <EditDeleteBtn
                link={`/section/${eventSectionId}/event/${id}`}
                routeState={{ prevPath: '/event', eventSectionId }}
                itemId={id}
                deleteMutation={deleteEvent}
                refresh={() => this.fetchEvents(true, true)}
              />
            )
          );
        }
      }
    ];
  };

  render() {
    const {
      currentTab,
      eventSectionId,
      currentTable,
      events,
      pagination,
      loading,
      deleted
    } = this.state;

    return (
      <div className="event-list-pages">
        <Menu
          onClick={this.handleChangeTab}
          selectedKeys={[currentTab]}
          mode="horizontal"
        >
          {this.props.eventSections.map((item) => {
            return (
              <Menu.Item
                key={item.id}
              >{`${item.title} ${item.subTitle}`}</Menu.Item>
            );
          })}
        </Menu>
        {eventSectionId && (
          <>
            <div className="add-button">
              <Button type="primary" onClick={this.handleMoveToEventPage}>
                Add Event
              </Button>
            </div>
            <Radio.Group
              name="radiogroup"
              value={currentTable}
              onChange={this.handleChangeTable}
            >
              <Radio value="orderInEvent">Orders in Work A</Radio>
              <Radio value="orderSection">Orders in Work B</Radio>
            </Radio.Group>
            <TableWithOrder
              columns={this.renderTableColumn()}
              data={events}
              loading={loading}
              sortingKey={currentTable}
              callbackChangeOrder={this.handleChangeOrder}
              deleted={deleted}
              pagination={pagination}
              callbackChangePage={this.callbackChangePage}
            />
          </>
        )}
      </div>
    );
  }
}

export default withEventSection(EventPage);
