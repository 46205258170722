import gql from 'graphql-tag';

export default gql`
  mutation uploadFile($file: Upload, $isVideo: Boolean) {
    uploadFile(file: $file, isVideo: $isVideo) {
      filename
      url
    }
  }
`;
