import React from 'react';

import { updateClient, deleteClient } from 'gql/mutation';
import { clients } from 'gql/query';

import ImageLinkList from './ImageLinkList';

class ClientPage extends React.PureComponent {
  render() {
    return (
      <div className="client-page">
        <ImageLinkList
          fetchQuery={clients}
          queryKey="clients"
          updateMutation={updateClient}
          deleteMutation={deleteClient}
          createLink="/clients/create"
          editLink="/clients/edit"
          addBtnText="Add Client"
          imgClass="client-img"
        />
      </div>
    );
  }
}

export default ClientPage;
