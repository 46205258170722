import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { DeleteTwoTone } from '@ant-design/icons';
import { ConfirmModal, errorModal, successModal } from 'components/Modal';
import { uploadFile, removeFile, removeFileVideo } from 'gql/mutation';
import { uploadFileToServer } from 'utils/utils';

class UploadFile extends React.Component {
  inputFile;
  img;
  constructor(props) {
    super(props);
    this.state = {
      url: props.url || '',
      uploadStatus: props.url ? 'success' : '',
      loading: props.loading,
      isLoaded: false
    };
    this.inputFile = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, url } = nextProps;
    if (prevState.loading && prevState.isLoaded && url) {
      return {
        url,
        uploadStatus: url ? 'success' : '',
        loading
      };
    }
    return null;
  }


  componentDidMount() {
    const { url, isVideo } = this.props;
    this.createLoader(url, isVideo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.createLoader(this.props.url, this.props.isVideo);
    }
  }

  createLoader = (src, isVideo) => {
    if (src) {
      if (isVideo) {
        let source = null
        this.img = document.createElement('video')
        source = document.createElement('source');
        this.img.oncanplay  = this.handleLoadComplete;
        source.onerror = this.handleError;
        source.type = "video/mp4";
        source.src = src;
        this.img.appendChild(source);
      } else {
        this.img = document.createElement('img');
        this.img.onload = this.handleLoadComplete;
        this.img.onerror = this.handleError;
        this.img.src = src;
      }
    } else {
      this.handleError();
    }
  };

  handleLoadComplete = () => {
    this.setState({
      uploadStatus: 'success',
      isLoaded: true
    });
  };

  handleError = () => {
    this.setState({
      uploadStatus: '',
      isLoaded: false
    });
  };

  handleOpenSelect = () => {
    if (this.inputFile && this.inputFile.current) {
      this.inputFile.current.click();
    }
  };

  handleSelectFile = () => {
    const {
      inputFile: { current }
    } = this;

    if (current && current.files && current.files[0]) {
      this.setState(
        {
          uploadStatus: 'loading'
        },
        () => {
          uploadFileToServer(
            current.files[0],
            this,
            () => {
              this.props.client
                .mutate({
                  mutation: uploadFile,
                  variables: { file: current.files[0], isVideo: this.props.isVideo }
                })
                .then(({ data: { uploadFile } }) => {
                  if (uploadFile) {
                    this.setState(
                      {
                        url: uploadFile.url,
                        uploadStatus: 'success'
                      },
                      () => {
                        this.props.onUploadFile(this.state.url);
                      }
                    );
                  }
                })
                .catch((err) => {
                  this.setState(
                    {
                      uploadStatus: 'failed'
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          uploadStatus: 'success'
                        });
                        errorModal('Failed to upload image!');
                      }, 1000);
                    }
                  );
                });
            },
            () => {
              this.setState({
                uploadStatus: ''
              });
            },
            this.props.isVideo
          );
        }
      );
    }
  };

  handleRemoveFile = () => {
    const { url } = this.state;
    const { isVideo, onDeleteFile } = this.props;
    if (url) {
      ConfirmModal(`Do you want to remove this ${isVideo ? 'video' : 'image'}?`, () => {
        this.setState(
          {
            uploadStatus: 'loading'
          },
          () => {
            if (onDeleteFile) {
              onDeleteFile(url);
              return;
            }
            this.props.client
              .mutate({
                mutation: isVideo ? removeFileVideo : removeFile,
                variables: {
                  url
                }
              })
              .then(({ data: { removeFile, removeFileVideo } }) => {
                const isRemoveFile = removeFile || removeFileVideo
                if (isRemoveFile && isRemoveFile.deleted) {
                  this.setState(
                    {
                      url: '',
                      uploadStatus: 'deleted'
                    },
                    () => {
                      this.props.onUploadFile('');
                      successModal(`Delete ${isVideo ? 'video' : 'image'} successfully.`);
                    }
                  );
                } else {
                  this.setState(
                    {
                      uploadStatus: 'failed'
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          uploadStatus: 'success'
                        });
                        errorModal(`Fail to delete ${isVideo ? 'video' : 'image'}!!`);
                      }, 1000);
                    }
                  );
                }
              })
              .catch((err) => {
                this.setState(
                  {
                    uploadStatus: 'failed'
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        uploadStatus: 'success'
                      });
                      errorModal(
                        err.graphQLErrors[0]
                          ? err.graphQLErrors[0].message
                          : err
                      );
                    }, 1000);
                  }
                );
              });
          }
        );
      });
    }
  };

  renderUploadForm = () => {
    const { imgWidth, imgHeight, isVideo } = this.props;
    const { uploadStatus, url, isLoaded } = this.state;
    switch (uploadStatus) {
      case 'loading':
        return <div>Uploading...</div>;
      case 'failed':
        return <div>Error!</div>;
      case 'success':
        return (
          <div
            className="image-box"
            style={{ width: imgWidth, height: imgHeight }}
          >
            <div className="btn-delete" onClick={this.handleRemoveFile}>
              <DeleteTwoTone />
            </div>
            {isLoaded && !isVideo && <img src={url} alt="url" />}
            {isLoaded && isVideo && (
              <video
                controls
                id="video"
                width={imgWidth}
                height={imgHeight}
              >
                <source src={url} type="video/mp4" />
              </video>
            )}
          </div>
        );
      default:
        return (
          <div className="box-input" onClick={this.handleOpenSelect}>
            <svg
              className="box-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="43"
              viewBox="0 0 50 43"
            >
              <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
            </svg>
            <input
              name="file"
              type="file"
              hidden
              accept="images/*"
              ref={this.inputFile}
              onChange={this.handleSelectFile}
            />
            <label htmlFor="file">
              <strong>{`Choose a ${isVideo ? 'video' : 'image'}`}</strong>
            </label>
          </div>
        );
    }
  };

  render() {
    return (
      <div className="upload-box">
        <div className="box">{this.renderUploadForm()}</div>
      </div>
    );
  }
}

UploadFile.propTypes = {
  url: PropTypes.string.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func,
  loading: PropTypes.bool,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string,
  isVideo: PropTypes.bool
};

UploadFile.defaultProps = {
  onDeleteFile: null,
  loading: false,
  imgWidth: '100%',
  imgHeight: '100%'
};

export default withApollo(UploadFile);
