import React from 'react';
import { addAward, updateAward } from 'gql/mutation';
import { singleAward } from 'gql/query';
import LinkDetail from './LinkDetail';

class AddEditAwardPage extends React.PureComponent {
  render() {
    return (
      <div className="add-edit-award">
        <LinkDetail
          imgWidth="406px"
          imgHeight="552px"
          fetchQuery={singleAward}
          queryKey="singleAdminAward"
          createMutation={addAward}
          updateMutation={updateAward}
          createMutationKey="addAward"
          updateMutationKey="updateAward"
          listLink="/awards"
        />
      </div>
    );
  }
}

export default AddEditAwardPage;
