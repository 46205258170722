import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'antd';
import CustomInput from 'components/CustomInput';
import SaveCancelBtn from 'components/SaveCancelBtn';
import { successModal, errorModal } from 'components/Modal';
import { changePassword, sendMailChangePassword } from 'gql/mutation';
import { handleMutation, handleError } from 'utils/utils';
import { domainURL } from 'constants/constants';

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      disabled: true
    };
  }

  handleChange = (e, key) => {
    const { value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [key]: value,
        disabled: false
      };
    });
  };

  validateForm = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (currentPassword && newPassword && confirmPassword) {
      return {
        currentPassword,
        newPassword,
        confirmPassword
      };
    }
    return false;
  };

  handleChangePassword = () => {
    if (this.validateForm()) {
      const { newPassword, confirmPassword, currentPassword } = this.state;
      const userId = sessionStorage.getItem('userId');

      if (!_.isEqual(newPassword, confirmPassword)) {
        errorModal('Confirm password must match with new password!');
        return;
      }

      handleMutation(
        this,
        changePassword,
        {
          id: userId,
          newPassword,
          oldPassword: currentPassword
        },
        'changePassword',
        () => {
          this.setState({
            disabled: true
          });
          this.props.client
            .mutate({
              mutation: sendMailChangePassword,
              variables: {
                id: userId,
                baseUrl: domainURL
              }
            })
            .then(({ data }) => {
              if (data) {
                successModal(
                  'Change password successfully. Please login again!',
                  () => {
                    sessionStorage.clear();
                    this.props.history.replace('/login');
                  }
                );
              }
            })
            .catch((err) => {
              console.log(err);
              errorModal(handleError(err, 'graphQLErrors'));
            });
        },
        (err) => {
          errorModal(handleError(err, 'graphQLErrors'));
        }
      );
    }
  };

  handleCancel = () => {
    this.props.history.push('/');
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      disabled
    } = this.state;
    return (
      <div className="change-password-page">
        <div className="wrapper">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomInput
                type="password"
                value={currentPassword}
                label="Current Password"
                onChange={(e) => this.handleChange(e, 'currentPassword')}
              />
            </Col>
            <Col span={24}>
              <CustomInput
                type="password"
                value={newPassword}
                label="New Password"
                onChange={(e) => this.handleChange(e, 'newPassword')}
              />
            </Col>
            <Col span={24}>
              <CustomInput
                type="password"
                value={confirmPassword}
                label="Confirm Password"
                onChange={(e) => this.handleChange(e, 'confirmPassword')}
              />
            </Col>
          </Row>
          <SaveCancelBtn
            saveCallback={this.handleChangePassword}
            cancelCallback={this.handleCancel}
            disabled={!this.validateForm() || disabled}
          />
        </div>
      </div>
    );
  }
}

export default ChangePasswordPage;
