import gql from 'graphql-tag';

export default gql`
  mutation updateFeedback(
    $id: String!
    $clientName: String
    $clientPosition: String
    $operator: String
    $description: String
    $urlImg: String
  ) {
    updateFeedback(
      id: $id
      clientName: $clientName
      clientPosition: $clientPosition
      operator: $operator
      description: $description
      urlImg: $urlImg
    ) {
      id
    }
  }
`;
