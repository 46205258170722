import gql from 'graphql-tag';

export default gql`
  query singleEventAdmin($id: String!) {
    singleEventAdmin(id: $id) {
      title
      description
      urlImg
      client
      brand
      category
      projectType
      year
      orderInHome
      orderInEvent
      orderSection
      showInHome
    }
  }
`;
