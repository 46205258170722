import React from 'react';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { Layout, Menu } from 'antd';
import routes from 'routes';
import {
  HighlightOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  HomeOutlined,
  InsertRowBelowOutlined,
  TeamOutlined,
  TrophyOutlined,
  BuildOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import Header from 'components/Header';
import { errorModal } from 'components/Modal';

import LoginPage from 'pages/LoginPage';
import BlockUserPage from 'pages/BlockUserPage';

import { getUser } from 'gql/query';
import { handleError } from 'utils/utils';

const { Sider, Content } = Layout;

const menu = [
  {
    title: 'Home Video',
    icon: <VideoCameraOutlined />,
    key: 'homeVideo',
    path: '/home-video'
  },
  {
    title: 'Event Section',
    icon: <ProfileOutlined />,
    key: 'eventSection',
    path: '/sections'
  },
  {
    title: 'Events',
    icon: <BuildOutlined />,
    key: 'events',
    path: '/event'
  },
  {
    title: 'Event In Home',
    icon: <InsertRowBelowOutlined />,
    key: 'eventInHome',
    path: '/event-in-home'
  },
  {
    title: 'Career',
    icon: <SnippetsOutlined />,
    key: 'career',
    path: '/career'
  },
  {
    title: 'Client Feedback',
    icon: <HighlightOutlined />,
    key: 'feedback',
    path: '/feedback'
  },
  {
    title: 'Client',
    icon: <TeamOutlined />,
    key: 'clients',
    path: '/clients'
  },
  {
    title: 'Award',
    icon: <TrophyOutlined />,
    key: 'awards',
    path: '/awards'
  }
];

class LayoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: false,
      selectedKey: 'eventSection'
    };
  }

  componentDidMount() {
    this.getUser();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props;
    if (pathname && pathname !== prevProps.location.pathname) {
      this.getUser();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      location: { pathname }
    } = nextProps;
    let key = '';
    menu.forEach((item) => {
      if (pathname.includes(item.path)) {
        key = item.key;
      }
    });
    return {
      selectedKey: key
    };
  }

  getUser = () => {
    const {
      history,
      location: { pathname }
    } = this.props;
    const token = sessionStorage.getItem('token');
    if (token) {
      try {
        const decodeToken = JSON.parse(window.atob(token.split('.')[1]));
        this.props.client
          .query({
            query: getUser,
            variables: {
              id: decodeToken.userId
            },
            fetchPolicy: 'network-only'
          })
          .then(({ data }) => {
            if (data && data.getUser) {
              if (data.getUser.status !== 'active') {
                errorModal(
                  'User has been block, please contact admin to get mor information!',
                  () => {
                    sessionStorage.clear();
                    history.push('/login');
                  }
                );
              }
            }
          })
          .catch((err) => {
            errorModal(handleError(err, 'graphQLErrors'));
          });
      } catch (error) {
        errorModal(error);
      }
    } else if (!pathname.includes('block-user')) {
      history.push('/login');
    }
  };

  handleCollapse = (isCollapse) => {
    this.setState({
      isCollapse
    });
  };

  handleChangeKey = (e) => {
    this.setState({
      selectedKey: e.key
    });
  };

  render() {
    const { isCollapse, selectedKey } = this.state;

    return (
      <Switch>
        {routes.map((page, index) => {
          return (
            <Route
              key={index}
              exact
              path={page.path}
              render={(props) => (
                <Layout style={{ minHeight: '100vh' }}>
                  <Sider
                    collapsible
                    collapsed={isCollapse}
                    onCollapse={this.handleCollapse}
                  >
                    <div className="sider-menu-logo" id="logo">
                      <Link to="/">
                        {!isCollapse ? (
                          <h1>PERFECTIV ADMIN</h1>
                        ) : (
                          <HomeOutlined />
                        )}
                      </Link>
                    </div>
                    <Menu
                      theme="dark"
                      mode="inline"
                      selectedKeys={[selectedKey]}
                      onClick={this.handleChangeKey}
                    >
                      {menu.map((item) => {
                        return (
                          <Menu.Item
                            key={item.key}
                            icon={item.icon}
                            title={item.title}
                            onClick={() => this.props.history.push(item.path)}
                          >
                            <Link to={item.path}>{item.title}</Link>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  </Sider>
                  <Layout className="site-layout">
                    <Header />
                    <Content className="site-content">
                      <div className="site-layout-background">
                        <page.component {...props} client={this.props.client} />
                      </div>
                    </Content>
                  </Layout>
                </Layout>
              )}
            />
          );
        })}
        <Route
          exact
          path="/login"
          render={() => <LoginPage {...this.props} />}
        />
        <Route
          exact
          path="/block-user"
          render={() => <BlockUserPage {...this.props} />}
        />
      </Switch>
    );
  }
}

export default withApollo(withRouter(LayoutPage));
