import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient, InMemoryCache, ApolloLink, from } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'pages/Layout';
import { errorModal } from 'components/Modal';
import { BASE_API_URL } from 'constants/constants';

const uploadLink = createUploadLink({
  uri: BASE_API_URL,
  opts: {
    credentials: 'same-origin'
  }
});

const httpLink = ApolloLink.split(
  () => true,
  uploadLink,
  new BatchHttpLink({
    uri: BASE_API_URL,
    fetchOptions: {
      credentials: 'same-origin'
    }
  })
);

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? token : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    graphQLErrors &&
    (graphQLErrors[0].message === 'Unauthorize' ||
      graphQLErrors[0].message === 'jwt expired')
  ) {
    sessionStorage.clear();
    errorModal(graphQLErrors[0].message);
    window.location.replace('/login');
  }
});

const apollo = new ApolloClient({
  link: errorLink.concat(from([authLink, httpLink])),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: 'all'
    }
  }
});

function App() {
  return (
    <ApolloProvider client={apollo}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
