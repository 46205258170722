import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import { UserOutlined, LogoutOutlined, LockOutlined } from '@ant-design/icons';

import { ConfirmModal } from 'components/Modal';

class Header extends React.PureComponent {
  handleLogout = () => {
    ConfirmModal('Are you sure want to logout?', () => {
      sessionStorage.clear();
      this.props.history.replace('/login');
    });
  };

  render() {
    const username = sessionStorage.getItem('username');

    const DropdownItem = (
      <Menu>
        <Menu.Item>
          <Link to="/change-password">
            <LockOutlined /> Change My Password
          </Link>
        </Menu.Item>
        <Menu.Item>
          <div onClick={this.handleLogout}>
            <LogoutOutlined /> Logout
          </div>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout.Header>
        <div className="ant-pro-global-header">
          <Dropdown overlay={DropdownItem} placement="bottomCenter">
            <div className="header-content">
              <UserOutlined />
              <span>Hello, {username}</span>
            </div>
          </Dropdown>
        </div>
      </Layout.Header>
    );
  }
}

export default withRouter(Header);
